// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';


.w-100 {
  width: 100%;
}

.small-chip {
  height: 10px;
  font-size: 10px;
}
.validator-error {
  color: red;
  align-self: start;
}
.departed-status {
  font-size: 12px;
  color: orange;
}
.no-padding {
  padding: 0;
}

@media all and (max-width: 565px) {
  .mat-datepicker-popup {
    width: 100% !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
  }
  .mat-datepicker-content {
    width: 100% !important;
  }
  .mat-calendar {
    width: 100% !important;
    height: 100% !important;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}